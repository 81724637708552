export default {

	// api请求地址
	baseUrl: 'https://admin.soppv.com',

	// 图片域名
	imgDomain: 'https://admin.soppv.com',

	// 腾讯地图key
	mpKey: '',

	// 客服
	webSocket: 'ws://admin.soppv.com',

	// api安全
	apiSecurity: true,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: ``,

}
